import styled from 'styled-components'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  @media (min-width: 680px) {
    padding-left: 140px;
    padding-right: 140px;
  }
  @media (min-width: 740px) {
    padding-left: 160px;
    padding-right: 160px;
  }
  @media (min-width: 1200px) {
    padding-left: 205px;
    padding-right: 155px;
    max-width: 1200px;
  }
  @media (min-width: 1300px) {
    padding-left: 205px;
    padding-right: 155px;
    max-width: 1300px;
  }
  @media (min-width: 1440px) {
    padding-left: 255px;
    padding-right: 205px;
    max-width: 1440px;
  }
`

export default Container

import React from 'react'
import Helmet from 'react-helmet'


const SEO = ({ title, description, images }) => {

    const meta_title_prefix = 'Oros - ';
    const meta_title_appendix = '';
    const fullTitle = `${meta_title_prefix ? meta_title_prefix + ' ' : ''}${title ? title : ''}${
      meta_title_appendix ? ' ' + meta_title_appendix : ''
    }`
    return (
      <Helmet>
        {title && <title>{fullTitle}</title>}
        {title && <meta property="og:title" content={fullTitle} />}
        {description && <meta name="description" content={description} />}
        {description && <meta property="og:description" content={description} />}
        {images &&
          images.map(({ image }, index) => {
            return image && image.url && <meta key={index} property="og:image" content={image.url} />
          })}
      </Helmet>
    )
  }

export default SEO
